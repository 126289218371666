@import "../../../common-utilities/styles/variables.scss";
@import "../../../common-utilities/styles/responsive.scss";
.technet-header-container {
  background: $gray-bg;
  box-sizing: border-box;
  // height: 75px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  @include respond-to(small) {
    font-size: 10px;
  }
  .left-c {
    display: flex;
    align-items: center;
  }
  .r-c {
    display: flex;
    align-items: center;
  }
}

.side-menu-header {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.menu-items {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;

  .menu-item {
    display: flex;
    grid-gap: 20px;
    cursor: pointer;
    align-items: center;
    padding: 5px 0px 5px 40px;
    .menu-name {
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      background-color: transparent;
      @include respond-to(small) {
        font-size: 10px;
      }
      :hover {
        background-color: transparent;
      }
    }
  }
  :hover {
    background: #00893895;
    color: white;
  }
  .menu-item-active {
    background: #008938;
    color: white;
  }
}

@import "../../packages/common-utilities/styles/responsive.scss";
@import "../../packages/common-utilities/styles/variables.scss";
.header-container {
  background: $gray-bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  position: sticky;
  top: 0;
  .subway-logo {
    width: 180px;
    padding-left: 10px;
    cursor: pointer;
    @include respond-to("small") {
      width: 100px;
      padding-left: 2px;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 1.3em;
    .bell-icon-con {
      position: absolute;
      width: 35px;
      top: -9px;
      right: 12px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(45, 156, 219, 0.15);
      border-radius: 12px;
      .bell-icon {
        position: absolute;
        width: 23px;
        height: 23px;
      }
    }
  }
  .user-name {
    margin-left: 10px;
  }
}
.logo-con {
  display: flex;
  align-items: center;
}

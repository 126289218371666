@import "../../../../../styles/responsive.scss";

.budget-list {
  padding: 20px 0px;
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .tabs-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .filter-row {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .finance-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .last-buttons {
      display: flex;
      gap: 20px;
    }
  }
  .payment-details-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
  }
}

@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.user-container {
  max-width: 99%;
  margin: auto;
  display: flex;
  padding: 2em;
  flex-direction: column;
  .button-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .button {
      background-color: "#0063cc";
    }
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 0.5fr;
  }
  .clickable {
    cursor: pointer;
    color: #008938;
  }
  .si-table-container {
    .si-row {
      grid-template-columns: 1fr 1fr 0.5fr;
    }
    @include respond-to("small") {
      .si-row {
        grid-template-columns: 1fr 1fr 0.5fr;
      }
    }
  }
}

.select-dropdown.select-department {
  margin-top: 8px;
  margin-bottom: 8px;
}
.outlined-btn {
  color: #008938 !important;
  border-color: #008938 !important;
}

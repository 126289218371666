@import "../../../common-utilities/styles/variables.scss";
@import "../../../common-utilities/styles/responsive.scss";

.department-container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  .department-action-icons {
    display: flex;
    justify-content: center;
  }
  .button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .search-field {
      display: flex;
      align-items: center;
      min-width: 20%;
      .main-search-icon {
        cursor: pointer;
      }
    }
    @media (max-width: 1300px) {
      .search-field {
        min-width: 30%;
      }
    }
    @media (max-width: 1100px) {
      .search-field {
        min-width: 35%;
      }
    }
    .button {
      background-color: "#0063cc";
    }
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr;
  }
  .clickable {
    cursor: pointer;
    color: #008938;
  }
  .si-table-container {
    .si-row {
      grid-template-columns: 2fr 2fr 1fr;
    }
    @include respond-to("small") {
      .si-row {
        grid-template-columns: 2fr 2fr;
      }
    }
  }
}
.region-user-con {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 10px;
  text-align: start;
}

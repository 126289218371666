@import "../../../../../styles/responsive.scss";

.po-list {
  padding: 20px 0px;
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .tabs-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .update-vendor-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .filter-row {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .po-action-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .download-icon {
    cursor: pointer;
  }
  .header-column-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .end-btns {
    margin-top: 10px;
    gap: 20px;
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
  }
}

/** @format */
@import "../../packages/common-utilities/styles/variables.scss";
.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  position: sticky;
  top: 0;
  .left-c {
    display: flex;
    align-items: center;
  }
  .r-c {
    display: flex;
    align-items: center;
  }
}

.side-menu-header {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.intranet-menu-items {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;

  .menu-item {
    display: flex;
    grid-gap: 15px;
    cursor: pointer;
    align-items: center;
    padding: 5px 0px 5px 40px;
    margin: auto;
    width: 250px;
    height: 45px;
    border-radius: 8px;
    .menu-name {
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: "center";
      letter-spacing: 0.1px;
      background-color: transparent;
    }
    :hover {
      background-color: transparent;
    }
  }
  :hover {
    background: #00b0750e;
  }

  .menu-active {
    width: 250px;
    height: 45px;
    background: #00b07426;
    border-radius: 8px;
    color: $primary;
  }
}
.user-action-con {
  width: inherit;
  gap: 10px;
  padding: 10px;
  // position: absolute;
  // bottom: 20px;
  .logout-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 3rem;
    .logout-img {
      margin-right: 10px;
      color: gray;
    }

    p {
      font-size: larger;
    }
  }
}

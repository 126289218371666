.button-color {
    background-color: #008938;
}

.technet-action-icons {
    .select-dropdown {
        margin-bottom: 10px;
    }
}

.Select {
    width: 444px !important;
    margin-right: 5px;
    z-index: 0 !important;
}

.Select.is-open {
	z-index: 1 !important;
}

.Select-menu {
    position: fixed !important;
    width: 440px !important;
    margin: 1px;
}

.d-flex {
    display: flex;
}

@import "../../common-utilities/styles/responsive.scss";
@import "../../common-utilities/styles/variables.scss";

.main-container {
  background-color: $gray-bg;
  flex-direction: column;
  display: flex;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .main-right-container {
    background-color: $gray-bg;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    height: 100vh;
    .content-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: -webkit-fill-available;
      background-color: $gray-bg;
    }
  }
}
.side-nav-header {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  h4 {
    margin-left: 1rem;
  }
}

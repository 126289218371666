@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.user-container {
  max-width: 99%;
  margin: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  .MuiBox-root {
    padding: 0;
  }
  .create-edit-select {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .button-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    .search-field {
      display: flex;
      align-items: center;
    }
    .main-search-icon {
      cursor: pointer;
    }
    .button {
      background-color: "#0063cc";
    }
    .button-container {
      display: flex;
      justify-content: end;
      column-gap: 10px;
    }
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
  }
  .clickable {
    cursor: pointer;
    color: #008938;
  }
  .si-table-container {
    .si-row {
      grid-template-columns: 1fr 1fr 1fr 0.5fr;
    }
    @include respond-to("small") {
      .si-row {
        grid-template-columns: 1fr 1fr 1fr 0.5fr;
      }
    }
  }
}

@import "../styles/responsive.scss";
@import "../../common-utilities/styles/variables.scss";

.subway-app-main-container {
  background-color: $gray-bg;
  flex-direction: column;
  display: flex;
  overflow: scroll;
  height: 100vh;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  .subway-app-current-app-container {
    display: grid;
    grid-template-columns: 15% 83%;
    gap: 10px;
    .subway-app-main-right-container {
      background-color: $gray-bg;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      height: 100vh;
      .content-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: -webkit-fill-available;
        background-color: $gray-bg;
      }
    }
    .subway-app-main-left-container {
      background-color: #008938;
      width: 89%;
    }
  }
}

@import "../../../../styles/responsive.scss";

.pl-report-container {
  .download-vendor-invoices {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    gap: 20px;
  }
  .header-section {
    display: flex;
    justify-content: space-between;
    .small-button {
      transform: scale(0.7);
      border-radius: 10%;
    }
  }
  .vendor-invoice-btns {
    display: flex;
    justify-content: end;
    gap: 20px;
  }
  padding: 20px;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .pl-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    .dropdown-list {
      min-width: 170px;
      @include respond-to("small") {
        width: 100%;
      }
    }
    @include respond-to("small") {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    .action-btns {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
    .vendor-name-list-container {
      width: 15%;
    }
  }
  .invoice-filter-container2 {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .invoice-table-filters {
    display: grid;
    grid-template-columns: 180px 1fr;
    max-width: 100%;
    gap: 20px;
  }
  .invoice-table {
    @include respond-to("small") {
      width: 85%;
    }

    .si-row {
      grid-template-columns: 100px 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 100px 100px 0.6fr 140px;
      word-break: break-all;
    }
    &.store {
      .si-row {
        grid-template-columns: 100px 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 100px 100px 0.6fr 140px;
        word-break: break-all;
      }
    }
  }
  .tab-section {
    padding-bottom: 10px;
  }
  .custom-common-modal-container {
    .invoice-tracking-document-modal {
      .modal-body {
        padding: 10px;
      }
    }
  }
}
.filter-width {
  width: 12rem !important;
}

.pl-report-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  .pl-spidle-upload-file {
    &.spidle-upload-file {
      width: 100%;
      max-width: 100%;
    }
  }
}

.home-container {
  padding: 20px;
  .active-tab {
    background-color: #f6f6f6;
    color: #008a52 !important;
    border-right: 1.5px solid #008a52;
    border-left: 1.5px solid #008a52;
    border-top: 1.5px solid #008a52;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
  }
}

@import "../../../common-utilities/styles/variables.scss";
.report-card-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;

  .report-card {
    bottom: 0%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    height: 150px;
    width: 300px;
    border-radius: 14px;
    transition: all ease-out 0.05s;
    .con-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .card-app-image {
        width: 60px;
        height: 60px;
      }
      .app-logo {
        width: 84px;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(0, 176, 116, 0.15);
      }
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: #464255;
        width: 100px;
        margin-left: 1.5rem;
      }
    }
  }
  .report-card:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 4px 4px 9px -4px rgba(99, 98, 99, 0.68);
    -moz-box-shadow: 4px 4px 9px -4px rgba(99, 98, 99, 0.68);
    box-shadow: 4px 4px 9px -4px rgba(99, 98, 99, 0.68);
    border-top: 2px solid $primary;
    border-bottom: 2px solid $primary;
    border-right: none;
    border-left: none;
  }
}

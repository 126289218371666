@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.store-container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  .ticket-action-icons {
    display: flex;
    justify-content: center;
  }
  .button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .button {
      background-color: "#0063cc";
    }
    .search-field {
      display: flex;
      min-width: 20%;
      align-items: center;
      .main-search-icon {
        cursor: pointer;
      }
    }
    @media (max-width:1300px) {
      .search-field {
        min-width: 30%;
      }
    }
    @media (max-width:1100px) {
      .search-field {
        min-width: 35%;
      }
    }
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr;
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }
  .si-table-container {
    .si-row {
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    }
    @include respond-to("small") {
      .si-row {
        grid-template-columns: 2fr 2fr;
      }
    }
  }
}
.select-dropdown {
  margin-bottom: 10px;
}
.create-escalation {
  display: flex;
  align-items: center;
  max-width: 50%;
  justify-content: space-between;
}
.delete-escalation-div {
  max-height: 10em;
  margin-bottom: 1rem;
  overflow-y: scroll;
  margin: 1em;
  .delete-escalation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f8f8;
    margin-bottom: 1em;
    p {
      margin: 0px;
      font-size: large;
    }
  }
}

.createNewParentlabel.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
}

.createcategorybutton.MuiButton-root {
  padding: 6px 16px;
  border-radius: 4px;
  color: #fff;
  background-color: #008938;
  box-shadow: 0px 3px 1px -2pxrgba (0, 0, 0, 0.2),
    0px 2px 2px 0pxrgba (0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.cancelcategorybutton.MuiButton-root {
  border: 1px solid rgba(0, 137, 82, 0.5);
  color: #008938;
  padding: 5px 15px;
  border-radius: 4px;
}

.createNewParentlabel .MuiCheckbox-colorPrimary.Mui-checked {
  color: #008938;
}

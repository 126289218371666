.qnet-main-report {
  .reports-table {
    min-height: 85vh;
    .download-report-button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 20px;
      grid-gap: 20px;
      .cases-stats {
        padding-left: 20px;
        font-weight: bolder;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
      }
      .inner-button-container {
        display: flex;
        gap: 20px;
      }
    }
    .si-row {
      grid-template-columns: 0.5fr 1fr 1fr 1.5fr 1.5fr 1fr;
    }
    .si-table-container.detail-report-table-container {
      overflow: scroll;
    }
  
    .si-row.detail-report-table-row {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      overflow: hidden;
      column-gap: 15px;
    }
    .si-header.detail-report-table-header {
      // overflow-y: scroll;
      min-height: 100px;
    }
    .si-row.detail-report-table-row.detail-report-table-header-row {
      min-height: 100px;
    }
    .clickable {
      cursor: pointer;
      color: #306fff;
    }
  
    .filter-section {
      padding: 10px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      grid-gap: 20px;
      .left-s {
        display: grid;
        align-items: center;
        max-width: 100%;
        column-gap: 20px;
        row-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .report-time-picker {
          margin: 8px;
          z-index: 1;
          position: relative;
          .rs-picker-default .rs-picker-toggle.rs-btn {
            min-width: 300px;
          }
        }
      }
  
      @media (max-width: 1300px) {
        .left-s {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
  
      @media (max-width: 1010px) {
        .left-s {
          grid-template-columns: 1fr 1fr;
        }
      }
  
      .hrm-range {
        display: flex;
        align-items: center;
        grid-gap: 20px;
      }
      .si-select__control {
        min-width: 200px;
      }
    }
    .clickable.userClickable {
      cursor: pointer;
      color: #306fff;
      text-decoration: underline;
    }
  
    .user-info-msg {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }
  }
  // date picker styling
  .rs-picker-default .rs-picker-toggle.rs-btn {
    width: 282px;
    padding: 10px;
    height: 2.8em;
  }
  .rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 67px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    border: 1px solid #e5e5ea;
    padding-left: 44px;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #008938 !important;
  }
  .rs-picker-daterange-panel .rs-btn-link {
    color: #008938 !important;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    border-radius: 21px;
    display: inline-block;
  }
  .rs-btn-primary {
    background-color: #008938 !important;
  }
  .rs-picker-daterange-header {
    color: #008938;
  }
  .rs-picker-toggle-value {
    color: #008938 !important;
  }
  .clickable {
    cursor: pointer;
    color: #008a52;
  }
  
  .custom-modal-container .modal-content.tableModal {
    width: 80%;
    max-width: 80%;
  }
  
  .rs-picker-default .rs-picker-toggle.rs-btn {
    min-width: 300px;
  }
  
}

@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.report-container {
  max-width: 99%;
  margin: auto;
  display: flex;
  padding: 2em;
  flex-direction: column;
  .button-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .button {
      background-color: "#0063cc";
    }
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .clickable {
    cursor: pointer;
    color: #008938;
  }
  .si-table-container {
    .si-row {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @include respond-to("small") {
      .si-row {
        grid-template-columns: 2fr 2fr 2fr;
      }
    }
  }
}

.select-dropdown.select-department {
  margin-top: 8px;
  margin-bottom: 8px;
}
.delete-approval-modal {
  .btn-container {
    margin-top: 25px;
    display: flex;
    justify-content: end;
    gap: 20px;
  }
}

.mainReportHeader {
  display: flex;
  margin-bottom: 2em;
  align-items: center;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
}

.reportsdateAndSelectContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr 0.5fr;
  align-items: center;
  column-gap: 1vw;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
  }
  .approvalLabel {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .store-type {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.downloadReportButton {
  display: flex;
  justify-content: end;
}

@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.doc-container {
  margin-top: 5em;
  .current-status-autocomplete {
    .MuiOutlinedInput-root {
      padding: 0px;
    }
  }
  .css-14puys2-MuiFormLabel-root-MuiInputLabel-root {
    top: -10px;
  }
  .table-con {
    border: 1px solid black;
    .table-row {
      display: flex;
      padding: 0.3em;
      border-bottom: 1px solid black;
    }
  }
  .main-commercial-container {
    display: flex;
    justify-content: center;
  }
}
.main-location-info-container {
  display: flex;
  justify-content: center;
}
.competitor-info-section {
  .end-container {
    display: flex;
    justify-content: end;
    gap: 20px;
    width: 100%;
  }

  margin-top: 10px;
  .si-table-container .si-header .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-table-container .si-body .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .add-others-button-container {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 10px;
    .add-others-button-select {
      width: 40%;
    }
  }
  .competitor-info-table {
    max-height: 65vh;
    // overflow: scroll;
  }
  @media only screen and (max-width: 1400px) {
    .competitor-info-table {
      .competitor-table-class {
        max-height: 60vh;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    .competitor-info-table {
      .competitor-table-class {
        max-height: 55vh;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .competitor-info-table {
      .competitor-table-class {
        max-height: 50vh;
      }
    }
  }
}
.nearby-subway-data-section {
  margin-top: 10px;
  .si-table-container .si-header .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-table-container .si-body .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .add-others-button-container {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 10px;
    .add-others-button-select {
      width: 40%;
    }
  }
  .end-container {
    display: flex;
    justify-content: end;
    gap: 20px;
    width: 100%;
  }
  .nearby-subway-table {
    max-height: 65vh;
    // overflow: scroll;
  }
  @media only screen and (max-width: 1400px) {
    .nearby-subway-table{
      .nearby-subway-class {
        max-height: 60vh;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    .nearby-subway-table {
      .nearby-subway-class {
        max-height: 55vh;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .nearby-subway-table {
      .nearby-subway-class {
        max-height: 50vh;
      }
    }
  }
}
.stage-gate-section {
  .end-con {
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 20px;
  }
  margin-top: 10px;
  .si-table-container .si-header .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .si-table-container .si-body .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.closeFormContainer {
  margin-top: 1rem;
  width: 98%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-bottom: 1em;
}
.leads-table-container {
  padding: 1em 2em 0em 2em;
  .user-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .search-icon {
    cursor: pointer;
  }
  .autocomplete-container {
    display: flex;
    align-items: center;
    .MuiAutocomplete-root.styled-text-field.bd-lead {
      width: 50%;
    }
    .autocomplete-on-value {
      width: 50%;
    }
    @include respond-to("small") {
      width: 100%;
      justify-content: center;
      margin-bottom: 1rem;
    }
  }
  .users-autocomplete {
    width: 50%;
    .css-d069fh-MuiInputBase-root-MuiFilledInput-root:before {
      border-bottom: none;
    }
    .MuiInputBase-root.MuiFilledInput-root {
      background: white;
    }
    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary:hover {
      border: 1px solid black;
    }
    .css-d069fh-MuiInputBase-root-MuiFilledInput-root:hover:not(
        .Mui-disabled
      ):before {
      border: none;
    }
    .css-d069fh-MuiInputBase-root-MuiFilledInput-root:before:hover {
      border-bottom: none;
    }
  }

  .create-new-container-admin {
    display: grid;
    grid-template-columns: 4fr 2fr;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to("small") {
      display: flex;
      flex-direction: column;
    }
    .create-button-container {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .create-button-container-end {
      display: flex;
      justify-content: flex-end;
    }
  }

  .create-new-container-user {
    display: grid;
    // justify-content: end;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    margin-bottom: 10px;
    .create-button-container {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
    .create-button-container-end {
      display: flex;
      justify-content: flex-end;
    }
  }
  .lead-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
}
.table-container {
  width: 100%;
  background-color: rgba(245, 242, 242, 0.654);
  .table-header-row {
    height: 2.5em;
    background-color: #008938;
    color: white;
    .status-table-cell {
      text-align: center;
      padding: 5px;
    }
  }
  .table-body-cell {
    text-align: center;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    padding: 5px;
  }
}
